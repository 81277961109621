<template>
  <v-card height="92vh" style="border-radius: 0">
    <v-card-title>
      <div class="title-box">
        <v-text-field v-model="filters.keyword" hide-details label="Поиск ...">
          <v-btn
            v-if="filters.keyword"
            slot="append"
            color="red"
            icon
            @click="clearInput()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn slot="append" icon @click="$emit('filter-role', filters)">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-text-field>
      </div>

      <v-select
        v-model="dateType"
        :items="dateFilter"
        item-value="id"
        item-text="name"
        menu-props="offsetY"
        hide-details
        dense
        outlined
        @input="dateType !== 3 ? $emit('filter-date', dateType) : ''"
      ></v-select>
      <v-row class="pt-2 mb-2">
        <v-col cols="6" class="pr-1 pb-0">
          <v-menu
            ref="menu"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            :dark="$dark.get()"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Начало"
                :value="date.startDate"
                readonly
                outlined
                v-bind="attrs"
                hide-details
                :disabled="dateType !== 3"
                dense
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date.startDate"
              no-title
              @change="
                $emit('filter-date', date);
                menu1 = !menu1;
              "
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6" class="pl-1 pb-0">
          <v-menu
            ref="menu"
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            :dark="$dark.get()"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Конец"
                :value="date.endDate"
                readonly
                outlined
                v-bind="attrs"
                hide-details
                :disabled="dateType !== 3"
                dense
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date.endDate"
              no-title
              @change="
                $emit('filter-date', date);
                menu2 = !menu2;
              "
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <div class="title-box">
        <v-select
          v-model="filters.role"
          :items="employeeFilter"
          item-value="id"
          item-text="name"
          hide-details
          dense
          outlined
          label="Сотрудники"
          class="mt-3"
          @change="$emit('filter-role', filters)"
        ></v-select>
      </div>
      <div class="title-box">
        <v-btn
          block
          color="success"
          class="mb-3"
          @click="showEployee = !showEployee"
          >Новый чат</v-btn
        >
        <v-select
          v-if="showEployee"
          :items="
            EMPLOYEE.cityEmployee.filter(
              (item) => item.role.id === 2 || item.role.id === 3
            )
          "
          item-value="id"
          item-text="username"
          hide-details
          dense
          outlined
          label="Сотрудники"
          @input="openNewChat($event)"
        ></v-select>
      </div>
    </v-card-title>
    <v-card-text>
      <v-simple-table height="50vh" fixed-header>
        <thead>
          <tr>
            <th></th>
            <th>Сотрудник</th>
            <th>Должность</th>
            <th>Активность</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="chat in chats" :key="chat.id">
            <td>
              <v-btn
                :color="activeChat === chat.chatId ? 'success' : ''"
                @click="
                  $emit('getChat', chat);
                  activeChat = chat.chatId;
                "
              >
                Чат
              </v-btn>
            </td>
            <td v-if="chat.recipientId">{{ chat.recipientId.username }}</td>
            <td v-if="chat.recipientId">{{ chat.recipientId.role }}</td>
            <td>
              <v-badge
                inline
                color="error"
                :content="`+ ${chat.counter} `"
              ></v-badge>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
    <v-card-actions>
      <v-btn block color="success" @click="$router.go(-1)"> Назад </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    chats: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeChat: 0,
      dateType: 1,
      dateFilter: [
        { id: 1, name: "Сегодня" },
        { id: 2, name: "Вчера" },
        { id: 3, name: "Период" },
      ],
      employeeFilter: [
        { id: null, name: "Все" },
        { id: 3, name: "Курьеры" },
        { id: 2, name: "Сборщики" },
      ],
      filters: {
        role: 1,
        keyword: "",
      },
      date: {},
      menu1: false,
      menu2: false,
      showEployee: false,
    };
  },
  computed: {
    ...mapGetters({
      EMPLOYEE: "Employee/STATE",
    }),
  },
  mounted() {
    this.employeeCity(this.$root.city);
  },
  methods: {
    ...mapActions({
      employeeCity: "Employee/GET_CITY_EMPLOYEE",
      OPEN_NEW_CHAT: "Chat/OPEN_NEW_CHAT",
    }),
    getEmployee() {
      this.employeeCity(this.$root.city);
    },
    async openNewChat(recipientId) {
      let response = await this.OPEN_NEW_CHAT(recipientId);
      this.showEployee = false;
      if (response.type !== "error") {
        this.$emit("newChat", response.chatId);
      }
    },
    clearInput() {
      this.filters.keyword = "";
      this.$emit("filter-role", this.filters);
    },
  },
};
</script>
<style lang="scss" scoped>
.chat {
  display: flex;
  gap: 10px;
  align-items: center;
}
.title-box {
  width: 100%;
  margin-bottom: 10px;
}
</style>
