<template>
  <v-dialog
    v-model="modal"
    width="80%"
    @click:outside="$emit('update:modal', false)"
  >
    <v-img :src="url" alt="big_chat_img"></v-img>
    <v-btn @click="$emit('update:modal', false)">Закрыть</v-btn>
  </v-dialog>
</template>

<script>
export default {
  name: "FotoModal",
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: "",
    },
  },
};
</script>
