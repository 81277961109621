var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"border-radius":"0"},attrs:{"height":"92vh"}},[_c('v-card-title',[_c('div',{staticClass:"title-box"},[_c('v-text-field',{attrs:{"hide-details":"","label":"Поиск ..."},model:{value:(_vm.filters.keyword),callback:function ($$v) {_vm.$set(_vm.filters, "keyword", $$v)},expression:"filters.keyword"}},[(_vm.filters.keyword)?_c('v-btn',{attrs:{"slot":"append","color":"red","icon":""},on:{"click":function($event){return _vm.clearInput()}},slot:"append"},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e(),_c('v-btn',{attrs:{"slot":"append","icon":""},on:{"click":function($event){return _vm.$emit('filter-role', _vm.filters)}},slot:"append"},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1)],1),_c('v-select',{attrs:{"items":_vm.dateFilter,"item-value":"id","item-text":"name","menu-props":"offsetY","hide-details":"","dense":"","outlined":""},on:{"input":function($event){_vm.dateType !== 3 ? _vm.$emit('filter-date', _vm.dateType) : ''}},model:{value:(_vm.dateType),callback:function ($$v) {_vm.dateType=$$v},expression:"dateType"}}),_c('v-row',{staticClass:"pt-2 mb-2"},[_c('v-col',{staticClass:"pr-1 pb-0",attrs:{"cols":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","dark":_vm.$dark.get()},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Начало","value":_vm.date.startDate,"readonly":"","outlined":"","hide-details":"","disabled":_vm.dateType !== 3,"dense":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.$emit('filter-date', _vm.date);
              _vm.menu1 = !_vm.menu1;}},model:{value:(_vm.date.startDate),callback:function ($$v) {_vm.$set(_vm.date, "startDate", $$v)},expression:"date.startDate"}})],1)],1),_c('v-col',{staticClass:"pl-1 pb-0",attrs:{"cols":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","dark":_vm.$dark.get()},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Конец","value":_vm.date.endDate,"readonly":"","outlined":"","hide-details":"","disabled":_vm.dateType !== 3,"dense":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.$emit('filter-date', _vm.date);
              _vm.menu2 = !_vm.menu2;}},model:{value:(_vm.date.endDate),callback:function ($$v) {_vm.$set(_vm.date, "endDate", $$v)},expression:"date.endDate"}})],1)],1)],1),_c('div',{staticClass:"title-box"},[_c('v-select',{staticClass:"mt-3",attrs:{"items":_vm.employeeFilter,"item-value":"id","item-text":"name","hide-details":"","dense":"","outlined":"","label":"Сотрудники"},on:{"change":function($event){return _vm.$emit('filter-role', _vm.filters)}},model:{value:(_vm.filters.role),callback:function ($$v) {_vm.$set(_vm.filters, "role", $$v)},expression:"filters.role"}})],1),_c('div',{staticClass:"title-box"},[_c('v-btn',{staticClass:"mb-3",attrs:{"block":"","color":"success"},on:{"click":function($event){_vm.showEployee = !_vm.showEployee}}},[_vm._v("Новый чат")]),(_vm.showEployee)?_c('v-select',{attrs:{"items":_vm.EMPLOYEE.cityEmployee.filter(
            function (item) { return item.role.id === 2 || item.role.id === 3; }
          ),"item-value":"id","item-text":"username","hide-details":"","dense":"","outlined":"","label":"Сотрудники"},on:{"input":function($event){return _vm.openNewChat($event)}}}):_vm._e()],1)],1),_c('v-card-text',[_c('v-simple-table',{attrs:{"height":"50vh","fixed-header":""}},[_c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v("Сотрудник")]),_c('th',[_vm._v("Должность")]),_c('th',[_vm._v("Активность")])])]),_c('tbody',_vm._l((_vm.chats),function(chat){return _c('tr',{key:chat.id},[_c('td',[_c('v-btn',{attrs:{"color":_vm.activeChat === chat.chatId ? 'success' : ''},on:{"click":function($event){_vm.$emit('getChat', chat);
                _vm.activeChat = chat.chatId;}}},[_vm._v(" Чат ")])],1),(chat.recipientId)?_c('td',[_vm._v(_vm._s(chat.recipientId.username))]):_vm._e(),(chat.recipientId)?_c('td',[_vm._v(_vm._s(chat.recipientId.role))]):_vm._e(),_c('td',[_c('v-badge',{attrs:{"inline":"","color":"error","content":("+ " + (chat.counter) + " ")}})],1)])}),0)])],1),_c('v-card-actions',[_c('v-btn',{attrs:{"block":"","color":"success"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Назад ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }