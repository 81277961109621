<template>
  <v-card
    style="border-radius: 0"
    height="92vh"
    class="d-flex flex-column justify-space-between"
  >
    <v-card-title class="elevation-5 title">
      Открыт чат с
      <span class="title_name"> {{ activeChat.recipientId.username }},</span>
      <span class="title_role"> {{ activeChat.recipientId.role }}</span>
    </v-card-title>
    <v-card-text ref="chat" class="overflow-auto pt-3" style="height: 100%">
      <div style="width: 100%; margin: 0 auto">
        <div
          v-for="item in messages"
          :key="item.id"
          :class="[
            { 'align-end': item.senderId === USER.userId },
            'd-flex flex-column mb-3',
          ]"
        >
          <p class="mb-3">
            {{
              item.senderId === USER.userId
                ? "Оператор службы доставки"
                : activeChat.recipientId.username
            }}
          </p>
          <div
            :class="[
              { receive_mes: item.senderId === USER.userId },
              'message pa-3 pb-6 pt-2 ',
            ]"
            style="position: relative"
          >
            {{ item.content }}
            <v-img
              v-if="item.photoUrl"
              :src="item.photoUrl"
              class="message_img"
              @click="openImageModal(item.photoUrl)"
            ></v-img>
            <div
              class="d-flex justify-end"
              style="
                font-size: 10px;
                position: absolute;
                right: 10px;
                bottom: 0;
              "
            >
              {{ getTime(item.timestamp) }}
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-text-field
        v-model="message"
        outlined
        dense
        hide-details
        label="Новое сообщение"
        append-outer-icon="mdi-send"
        @click:append-outer="
          $emit('send-message', { content: message });
          message = null;
        "
        @keydown.enter="
          $emit('send-message', { content: message });
          message = null;
        "
      ></v-text-field>
      <!-- отправка картинок -->
      <input
        ref="imagesInput"
        type="file"
        hidden
        accept="image/*"
        @change="uploadImage($event.target.files)"
      />
      <v-btn icon @click="$refs.imagesInput.click()"
        ><v-icon>mdi-paperclip-plus</v-icon></v-btn
      >
    </v-card-actions>
    <FotoModal :modal.sync="imageModal" :url="imageUrl" />
  </v-card>
</template>
<script>
import FotoModal from "@/components/Chat/FotoModal";
import { mapGetters } from "vuex";
import moment from "moment";
import api from "@/api";
export default {
  components: {
    FotoModal,
  },
  props: {
    messages: {
      type: Array,
      required: true,
    },
    activeChat: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      message: "",
      imageModal: false,
      imageUrl: "",
    };
  },
  computed: {
    ...mapGetters({
      USER: "User/STATE",
      EMPLOYEE: "Employee/STATE",
    }),
  },
  watch: {
    messages: {
      async handler() {
        this.scroll();
      },
      deep: true,
    },
  },
  mounted() {
    this.scroll();
  },
  methods: {
    scroll() {
      setTimeout(() => {
        const elem = this.$refs.chat;
        elem.scrollTo({ behavior: "smooth", top: elem.scrollHeight });
      }, 300);
    },
    getTime(date) {
      if (date) {
        return moment(date).format("HH:mm");
      }
    },
    async uploadImage(imageFile) {
      this.loading = true;
      let data = new FormData();
      data.append("file", imageFile[0]);
      let request = {
        file: data,
        chatId: this.activeChat.chatId,
      };
      let response = await api.Chat.sendImage(request);
      if (response.type !== "error") {
        this.$emit("send-message", { photoUrl: response });
      }
      // Очищаем выбранные файлы
      this.$refs.imagesInput.value = "";
      this.loading = false;
    },
    openImageModal(url) {
      this.imageModal = true;
      this.imageUrl = url;
    },
  },
};
</script>
<style lang="scss">
.title {
  background-color: hsl(122, 39%, 49%);
  color: white;
  font-weight: 400;
  &_name {
    color: yellow;
    margin-left: 10px;
    font-weight: 800;
  }
  &_role {
    margin-left: 10px;
    font-weight: 800;
  }
}
.message {
  border-radius: 5px;
  width: 40%;
  border-top-left-radius: 0;
  border-top-right-radius: 5px;
  font-size: 0.9rem;
  max-width: 85%;
  background-color: #605050;
  color: white;
  margin-left: 10px;
  position: relative;
  &_img {
    cursor: pointer;
  }
}
.message::before {
  content: "";
  position: absolute;
  left: -10px;
  top: 0px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 15px 20px 0;
  border-color: transparent #605050 transparent transparent;
}
.receive_mes {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 0 !important;
  text-align: right;
  margin-right: 10px;
  margin-left: 0 !important;
  background-color: #7dc421;
}
.receive_mes::after {
  content: "";
  position: absolute;
  right: -10px;
  top: 0px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 15px 0 0;
  border-color: #7dc421 transparent transparent transparent;
}
.receive_mes::before {
  content: "" !important;
  border: none;
}
</style>
