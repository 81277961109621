<template>
  <div>
    <loader v-if="loading"></loader>
    <v-row v-else class="ma-0 mt-n3 ma-md-n3 chat">
      <v-btn
        v-if="mini"
        color="success"
        class="mb-2"
        @click="showList = !showList"
        >{{ showList ? "Свернуть" : "Раскрыть" }} список чатов</v-btn
      >
      <v-col
        v-show="showChatList"
        :cols="mini ? 12 : 4"
        class="d-md-block pb-0 pr-0"
      >
        <ChatList
          :show="mini"
          :chats="chats"
          @getChat="getChat($event)"
          @filter-date="filterChatsByDate($event)"
          @filter-role="filterChatsByRole($event)"
          @newChat="setNewChat($event)"
        ></ChatList>
      </v-col>
      <v-col
        v-if="activeChat.chatId"
        :cols="mini ? 12 : 8"
        class="pb-md-0 pa-md-3 pa-0"
      >
        <chat-message
          :messages="activeChatMessages"
          :active-chat="activeChat"
          @send-message="sendMessage($event)"
        ></chat-message>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ChatList from "@/components/Chat/ChatList";
import ChatMessage from "@/components/Chat/ChatMessage";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Loader from "@/components/Loader";
import Stomp from "webstomp-client";
export default {
  components: {
    Loader,
    ChatMessage,
    ChatList,
  },
  data() {
    return {
      loading: true,
      showList: true,
      stompClient: null,
      chats: [],
      body: {
        senderId: null,
        keyword: "",
        role: null,
      },
      activeChatMessages: [],
      activeChat: {},
    };
  },
  computed: {
    ...mapGetters({
      USER: "User/STATE",
    }),
    mini() {
      let mobile = false;
      if (this.$vuetify.breakpoint.name === "xs") {
        mobile = true;
      }
      return mobile;
    },
    showChatList() {
      let show = true;
      if (this.mini && !this.showList) {
        show = false;
      }
      return show;
    },
  },
  async mounted() {
    await this.setChat();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      GET_CHATS: "Chat/GET_CHATS",
      GET_MESSAGES: "Chat/GET_MESSAGES",
    }),
    async setNewChat(chatId) {
      this.body.startDate = moment().format("YYYY-MM-DD");
      this.body.endDate = moment().format("YYYY-MM-DD");
      const response = await this.GET_CHATS(this.body);
      if (response.type !== "error") {
        this.chats = response;
      }
      const newChat = this.chats.find((item) => item.chatId === chatId);
      this.getChat(newChat);
    },
    async setChat() {
      this.loading = true;
      this.body.startDate = moment().format("YYYY-MM-DD");
      this.body.endDate = moment().format("YYYY-MM-DD");
      this.body.senderId = this.USER.userId;
      const response = await this.GET_CHATS(this.body);
      if (response.type !== "error") {
        this.chats = response;
      }
      const socket = new WebSocket(process.env.VUE_APP_WS_URL);
      this.stompClient = Stomp.over(socket);
      this.stompClient.connect(
        {},
        (frame) => {
          var t = frame;
          if (t.command == true) {
            return;
          }
          this.stompClient.subscribe(
            `/user/${this.USER.userId}/queue/messages`,
            (data) => {
              let sock = JSON.parse(data.body);
              if (this.activeChat.chatId === sock.chatId) {
                this.activeChatMessages.push(sock);
              } else {
                const index = this.chats.findIndex(
                  (item) => item.chatId === sock.chatId
                );
                this.chats[index].counter++;
              }
            }
          );
        },
        () => {
          console.log("error");
        }
      );
      this.loading = false;
    },
    async filterChatsByRole(filter) {
      this.body.role = filter.role;
      this.body.keyword = filter.keyword;
      this.chats = await this.GET_CHATS(this.body);
    },
    async filterChatsByDate(filter) {
      // сегодня
      if (filter === 1) {
        this.body.startDate = moment().format("YYYY-MM-DD");
        this.body.endDate = moment().format("YYYY-MM-DD");
        this.chats = await this.GET_CHATS(this.body);
      }
      // вчера
      if (filter === 2) {
        this.body.startDate = moment().add(-1, "d").format("YYYY-MM-DD");
        this.body.endDate = moment().add(-1, "d").format("YYYY-MM-DD");
        this.chats = await this.GET_CHATS(this.body);
      }
      // период
      if (filter.startDate && filter.endDate) {
        this.body.startDate = filter.startDate;
        this.body.endDate = filter.endDate;
        this.chats = await this.GET_CHATS(this.body);
      }
    },
    async getChat(chat) {
      const payload = {
        chatId: chat.chatId,
        senderId: this.USER.userId,
      };
      const data = await this.GET_MESSAGES(payload);
      this.activeChatMessages = data;
      this.activeChat = chat;
      this.showList = false;
    },
    async sendMessage(payload) {
      const message = {
        senderId: this.USER.userId,
        recipientId: this.activeChat.recipientId.id,
        timestamp: moment().format().split("+")[0],
        chatId: this.activeChat.chatId,
        ...payload,
      };
      this.stompClient.send("/ws/chat", JSON.stringify(message));
    },
  },
};
</script>
<style lang="scss" scoped>
.chat {
  @media screen and (max-width: 720px) {
    display: flex;
    flex-direction: column;
  }
}
</style>
